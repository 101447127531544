import Head from "next/head";
import Image from "next/image";
import { Inter } from "next/font/google";
import styles from "@/styles/Home.module.css";
import { Configuration, OpenAIApi } from "openai";
import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Loading,
  Card,
  Grid,
  Text,
  Container,
  Navbar,
  Link,
  Modal,
  Input,
  Row,
  Checkbox,
  Dropdown,
} from "@nextui-org/react";
import { useTheme as useNextTheme } from "next-themes";
import { Switch, useTheme } from "@nextui-org/react";
import NavBarComponent from "@/components/navbar";
import Lottie from "react-lottie-player";
import { auth, db, analytics } from "../config/firebase";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

import { getAnalytics, logEvent } from "firebase/analytics";

import lottieJson from "../lottie/137551-man-walk-robot.json";
import LoadingLottie from "@/components/loadingLottie";

import { useRouter } from "next/router";

import mixpanel from "mixpanel-browser";

const inter = Inter({ subsets: ["latin"] });

const configuration = new Configuration({
  organization: "org-B9Ry8EHmiy9fNJBMgGZJQwKc",
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [userName, setUserName] = useState("");
  const [location, setLocation] = useState("");
  const [numberOfPosts, setNumberOfPosts] = useState(new Set([1]));
  const [name, setName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [visible, setVisible] = useState(false);
  const { setTheme } = useNextTheme();
  const { isDark, type } = useTheme();

  const router = useRouter();

  mixpanel.init("ff03467d8713f07fd4313ac80253840d", { debug: true });

  useEffect(() => {
    if (auth.currentUser) {
      //console.log("user is not null., lets get their profile");
      getUserProfile(auth.currentUser.email);
      mixpanel.identify(auth.currentUser.email);
    } else {
      router.push("/login");
    }
  }, []);

  async function getUserProfile(email) {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      setName(docSnap.get("FirstName"));
      setBusinessName(docSnap.get("BusinessName"));
      setBusinessDescription(docSnap.get("BusinessDescription"));
      setBusinessLocation(docSnap.get("BusinessLocation"));
    } else {
      // doc.data() will be undefined in this case
      //"No such document!");
    }
  }

  const selectedNumberOfPostsValue = useMemo(
    () => Array.from(numberOfPosts).join(", ").replaceAll("_", " "),
    [numberOfPosts]
  );

  function toggleLoading() {
    if (showLoading) {
      setShowLoading(false);
    } else {
      setShowLoading(true);
    }
  }

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <>
      <main className={styles.main}>
        {/* <Navbar isBordered variant="floating">
          <Navbar.Brand>
            <Text b color="inherit" hideIn="xs">
              GrowthBot
            </Text>
          </Navbar.Brand>

          <Navbar.Content>
            {" "}
         
            <Switch
              checked={isDark}
              onChange={(e) => setTheme(e.target.checked ? "dark" : "light")}
            />
            <Navbar.Item>
              <Button
                color="primary"
                auto
                flat
                as={Link}
                onPress={() => setVisible(true)}
              >
                Setup
              </Button>
            </Navbar.Item>
          </Navbar.Content>
        </Navbar> */}
        <NavBarComponent />
        <Container>
          <Grid.Container gap={2} justify="center" style={{ marginTop: 50 }}>
            {showLoading ? (
              <>
                <Button>
                  <Loading color="currentColor" size="sm" /> &nbsp;
                  Generating...
                </Button>
              </>
            ) : (
              <>
                <Grid.Container justify="center">
                  <Grid lg={3}>
                    <Text>How many posts would you like to generate?</Text>
                  </Grid>
                  <Grid lg={1}>
                    <Dropdown>
                      <Dropdown.Button
                        flat
                        color="secondary"
                        css={{ tt: "capitalize" }}
                      >
                        {selectedNumberOfPostsValue}
                      </Dropdown.Button>
                      <Dropdown.Menu
                        disallowEmptySelection
                        selectionMode="single"
                        selectedKeys={numberOfPosts}
                        onSelectionChange={setNumberOfPosts}
                        aria-label="Static Actions"
                      >
                        <Dropdown.Item key="1">1</Dropdown.Item>
                        <Dropdown.Item key="2">2</Dropdown.Item>
                        <Dropdown.Item key="3">3</Dropdown.Item>
                        <Dropdown.Item key="4">4</Dropdown.Item>
                        <Dropdown.Item key="5">5</Dropdown.Item>
                        {/* <Dropdown.Item key="15">15</Dropdown.Item>
                    <Dropdown.Item key="20">20</Dropdown.Item>
                    <Dropdown.Item key="25">25</Dropdown.Item>
                    <Dropdown.Item key="30">30</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid>
                  <Grid lg={12} justify="center" style={{ marginTop: 25 }}>
                    <Button onClick={() => getData()}>
                      {" "}
                      &nbsp; Generate Posts
                    </Button>
                  </Grid>
                </Grid.Container>
              </>
            )}
          </Grid.Container>
          <Grid.Container gap={2} justify="center">
            {/* {prompt} */}

            {showLoading ? (
              <>
                <LoadingLottie />
              </>
            ) : (
              posts.map((post) => (
                <Grid lg={4} xs={12} justify="center">
                  <Card css={{ p: "$6", mw: "400px" }}>
                    <Card.Header>
                      <img src="./logo.jpg" width="34px" height="34px" />
                      <Grid.Container css={{ pl: "$6" }}>
                        <Grid xs={12}>
                          <Text h4 css={{ lineHeight: "$xs" }}>
                            {businessName}
                          </Text>
                        </Grid>
                      </Grid.Container>
                    </Card.Header>
                    <Card.Body css={{ py: "$2" }}>
                      <Text>{post.split("^")[0].split(":"[1])}</Text>
                    </Card.Body>
                    <Card.Footer>{post.split("^")[1]}</Card.Footer>
                  </Card>
                </Grid>
              ))
            )}
          </Grid.Container>
        </Container>
      </main>
    </>
  );

  async function getData() {
    var contentArray = [];

    //console.log("Sending the request: ");
    setShowLoading(true);

    var promptString =
      "Speak like you are a big time digital marketing agency. " +
      "My business name is " +
      businessName +
      ". " +
      "My business is located in " +
      location +
      ". " +
      "A description of my business is " +
      businessDescription +
      ". " +
      "\n" +
      " - Lookup the best performing posts on instagram relevant to your business. " +
      " - Lookup the best performing hashtags relevant to your business and the post. " +
      " Here are some instructions on how to format your response: " +
      " - Split each social media post with a pipe delimiter. " +
      " - Separate the hashtags from the social media post with a carrot delimiter.  " +
      " - When you are finished, make sure each post has 30 hashtags. " +
      //" - Remove the Post 1, Post 2 indicator. " +
      //" 5.) remove the words 'post content'.  " +
      " - Put the hashtags beneath the post but don't write the word Hashtags.  " +
      " - When you are done, make sure there are " +
      selectedNumberOfPostsValue +
      " posts. " +
      " - Make sure the posts are not blank. " +
      "Format the posts like this: " +
      "\n" +
      "POST CONTENT ^" +
      "\n" +
      "HASHTAGS" +
      "\n" +
      "|POST CONTENT^" +
      "\n" +
      "HASHTAGS ";

    var contentString =
      "Create " +
      selectedNumberOfPostsValue +
      " instagram posts with at least 50 words for my business with 30 high peforming hashtags.";

    //console.log("Getting " + selectedNumberOfPostsValue);
    //console.log(promptString);

    setPrompt(promptString + "\n" + contentString);

    let start = Date.now();

    await openai
      .createChatCompletion({
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content: promptString,
          },
          {
            role: "user",
            content: contentString,
          },
        ],
      })
      .then(async (completion) => {
        let end = Date.now();

        let elapsed = end - start;

        //console.log("That took " + elapsed / 1000 + " seconds");

        setShowLoading(false);
        //console.log("raw data: " + JSON.stringify(completion));
        //console.log();

        var content = completion.data.choices[0].message.content;
        //console.log(content);
        //console.log("Got " + content.split("|").length + " posts");

        for (var i = 0; i < content.split("|").length; i++) {
          contentArray.push(content.split("|")[i]);
        }

        //console.log(completion.data);

        setPosts(contentArray);

        // logEvent(analytics, "post_generation", {
        //   user: auth.currentUser,
        //   prompt: promptString,
        //   response: contentArray,
        // });

        mixpanel.track("Post Generation", {
          prompt: promptString,
          response: contentArray,
        });

        let user_email = auth.currentUser.email;

        //console.log("Sendinig prompt: " + promptString);

        try {
          const body = {
            user_email,
            promptString,
            contentArray: completion.data.choices[0].message.content,
          };
          await fetch(`/api/posts`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          });
          //await Router.push("/drafts");
        } catch (error) {
          console.error(error);
        }

        // fetch(
        //   "https://us-central1-the-path-to-growth.cloudfunctions.net/addPrompt?prompt='" +
        //     promptString +
        //     "'&response='" +
        //     completion.data.choices[0].message.content +
        //     "'&type='post'" +
        //     "&user='" +
        //     JSON.stringify(auth.currentUser) +
        //     "'"
        // )
        //   .then((res) => res.json())
        //   .then(
        //     (result) => {
        //       console.log(result);
        //     },
        //     // Note: it's important to handle errors here
        //     // instead of a catch() block so that we don't swallow
        //     // exceptions from actual bugs in components.
        //     (error) => {
        //       console.log(error);
        //     }
        //   );
      });

    //setData(contentArray);
  }
}
