import { initializeApp, getApps } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyDR5Lg_zQc0RniVy-G6E97yJGDsJDHLStQ",
  authDomain: "the-path-to-growth.firebaseapp.com",
  databaseURL: "https://the-path-to-growth-default-rtdb.firebaseio.com",
  projectId: "the-path-to-growth",
  storageBucket: "the-path-to-growth.appspot.com",
  messagingSenderId: "237242179821",
  appId: "1:237242179821:web:0b327ed4eeb163b4682ce4",
  measurementId: "G-FERRRN9P8B",
};

const app = initializeApp(config);

const db = getFirestore(app);
const auth = getAuth(app);

let analytics;

// if (app) {
//   const analytics = typeof window !== "undefined" ? getAnalytics(app) : null;
// }

export { db, auth };
