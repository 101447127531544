import "@/styles/globals.css";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { AuthProvider } from "../context/AuthContext";
import { useState, useContext } from "react";
import Posts from "./posts";
import MarketingStrategy from "./marketingstrategy";
import AppContext, { useGlobalContext } from "../context/AppContext";

const lightTheme = createTheme({
  type: "light",
});

const darkTheme = createTheme({
  type: "dark",
});

function MyApp({ Component, pageProps }) {
  return (
    <AppContext>
      <AuthProvider>
        <NextThemesProvider
          defaultTheme="dark"
          attribute="class"
          value={{
            light: lightTheme.className,
            dark: darkTheme.className,
          }}
        >
          <NextUIProvider>
            <Component {...pageProps}></Component>
          </NextUIProvider>
        </NextThemesProvider>
      </AuthProvider>
    </AppContext>
  );
}

export default MyApp;
