import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "../config/firebase";
import { useRouter } from "next/router";
import { collection, addDoc, getDocs, getDoc, doc } from "firebase/firestore";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        getUserProfile(user.email);
      }
      router.push("/");
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      //console.log("user is not null., lets get their profile");
      getUserProfile(auth.currentUser.email);
    }
  }, []);

  async function getUserProfile(email) {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      setUserProfile({
        name: docSnap.get("FirstName"),
        businessName: docSnap.get("BusinessName"),
        businessDescription: docSnap.get("BusinessDescription"),
        businessLocation: docSnap.get("BusinessLocation"),
      });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async function saveUserProfile() {
    const docRef = doc(db, "Users", currentUser.email);

    await updateDoc(docRef, {
      FirstName: userProfile.name,
    });
  }

  // const login = (email, password) => {
  //   return auth.signInWithEmailAndPassword(email, password);
  // };

  // const logout = () => {
  //   return auth.signOut();
  // };

  const value = {
    currentUser,
    userProfile,
    saveUserProfile,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
