import Head from "next/head";
import { IM_Fell_French_Canon, Inter } from "next/font/google";
import styles from "@/styles/Home.module.css";
import { Configuration, OpenAIApi } from "openai";
import { useState, useEffect, useMemo, useContext } from "react";
import {
  Button,
  Loading,
  Card,
  Grid,
  Text,
  Container,
  Navbar,
  Link,
  Modal,
  Input,
  Row,
  Checkbox,
  Dropdown,
  Image,
  Badge,
} from "@nextui-org/react";
import { useTheme as useNextTheme } from "next-themes";
import { Switch, useTheme } from "@nextui-org/react";
import { useAuth } from "../context/AuthContext";
import { auth, db } from "../config/firebase";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useGlobalContext } from "../context/AppContext";
import { useRouter } from "next/router";

export default function NavBarComponent(activeLink) {
  const { isDark, type } = useTheme();
  const { setTheme } = useNextTheme();
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");

  const { currentUser } = useAuth();

  const router = useRouter();

  const { currentPage, setCurrentPage } = useGlobalContext();

  var postActive;
  var msActive;

  useEffect(() => {
    if (auth.currentUser) {
      //console.log("user is not null., lets get their profile");
      getUserProfile(auth.currentUser.email);
    }
  }, []);

  function logout() {
    setVisible(false);
    auth.signOut();
    setTimeout(() => {
      router.push("/login");
    }, 500);
  }

  async function getUserProfile(email) {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      setName(docSnap.get("FirstName"));
      setBusinessName(docSnap.get("BusinessName"));
      setBusinessDescription(docSnap.get("BusinessDescription"));
      setBusinessLocation(docSnap.get("BusinessLocation"));

      if (
        docSnap.get("FirstName") == "" ||
        //docSnap.get("BusinessName") == nil ||
        docSnap.get("BusinessName") == "" ||
        //docSnap.get("BusinessDescription") == nil ||
        docSnap.get("BusinessDescription") == "" ||
        // docSnap.get("BusinessLocation") == nil ||
        docSnap.get("BusinessLocation") == ""
      ) {
        setVisible(true);
      }
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such document!");
    }
  }

  async function saveUserProfile() {
    const docRef = doc(db, "Users", currentUser.email);

    await updateDoc(docRef, {
      BusinessName: businessName,
      FirstName: name,
      BusinessLocation: businessLocation,
      BusinessDescription: businessDescription,
    });

    setVisible(false);
  }

  return (
    <>
      <Navbar isBordered variant="floating">
        <Navbar.Brand>
          {/* <AcmeLogo /> */}
          <Image
            width={150}
            //height={180}
            src="/logo.png"
            alt="Default Image"
            fill
          />
          <Badge color="primary">ALPHA</Badge>
          {/* <Text b color="inherit" hideIn="xs">
            Agency In Your Pocket &nbsp;
           
          </Text> */}
        </Navbar.Brand>

        <Navbar.Content>
          <Navbar.Link
            onPress={() => setCurrentPage("posts")}
            isActive={currentPage == "posts" ? true : false}
          >
            Create Posts
          </Navbar.Link>
          <Navbar.Link
            onPress={() => setCurrentPage("marketingstrategy")}
            isActive={currentPage == "marketingstrategy" ? true : false}
          >
            Create Strategy
          </Navbar.Link>

          {/* <Switch
            checked={isDark}
            onChange={(e) => setTheme(e.target.checked ? "dark" : "light")}
          /> */}
          {/* <Navbar.Item>{userProfile.name}</Navbar.Item> */}
          <Navbar.Item>
            <Button
              color="primary"
              auto
              flat
              as={Link}
              onPress={() => setVisible(true)}
            >
              Setup
            </Button>
          </Navbar.Item>
        </Navbar.Content>
      </Navbar>
      <Modal
        //closeButton
        preventClose
        blur
        aria-labelledby="modal-title"
        open={visible}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Setup
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            clearable
            bordered
            color="primary"
            size="lg"
            value={name}
            label="Your Name"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            value={businessName}
            label="Business Name"
            onChange={(e) => setBusinessName(e.target.value)}
          />

          <Input
            clearable
            bordered
            color="primary"
            size="lg"
            value={businessLocation}
            label="Business Location"
            onChange={(e) => setBusinessLocation(e.target.value)}
          />
          <Input
            clearable
            bordered
            color="primary"
            size="lg"
            value={businessDescription}
            label="Short description of your business"
            onChange={(e) => setBusinessDescription(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button auto color="error" onPress={() => setVisible(false)}>
            Close
          </Button>
          <Button auto onPress={() => saveUserProfile()}>
            Save
          </Button>
          <Button auto onPress={() => logout()}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
