import { Grid, Text } from "@nextui-org/react";

import Lottie from "react-lottie-player";

import lottieJson from "../lottie/137551-man-walk-robot.json";

export default function LoadingLottie() {
  return (
    <Grid.Container justify="center" xs={12}>
      <Grid>
        <Text style={{ textAlign: "center", marginTop: 80 }}>
          Your content is generating, give it about 60 seconds.
        </Text>
        <Text style={{ textAlign: "center" }}>
          GPT-4 is powerful, but slow at the moment.
        </Text>
        <Text style={{ textAlign: "center" }}>
          But hey, it's better than doing all this work yourself right!
        </Text>

        <Lottie
          loop
          animationData={lottieJson}
          play
          speed={1}
          style={{ width: "100%", height: "100%" }}
        />
      </Grid>
    </Grid.Container>
  );
}
