import Head from "next/head";
import Image from "next/image";
import { Inter } from "next/font/google";
import styles from "@/styles/Home.module.css";
import { Configuration, OpenAIApi } from "openai";
import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Loading,
  Card,
  Grid,
  Text,
  Container,
  Navbar,
  Link,
  Modal,
  Input,
  Row,
  Checkbox,
  Dropdown,
} from "@nextui-org/react";
import { useTheme as useNextTheme } from "next-themes";
import { Switch, useTheme } from "@nextui-org/react";
import NavBarComponent from "@/components/navbar";
import LoadingLottie from "@/components/loadingLottie";
import ReactMarkdown from "react-markdown";
import emailjs from "@emailjs/browser";
import { auth, db } from "../config/firebase";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

import { useRouter } from "next/router";

const inter = Inter({ subsets: ["latin"] });

const configuration = new Configuration({
  organization: "org-B9Ry8EHmiy9fNJBMgGZJQwKc",
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

export default function MarketingStrategy() {
  const [strategy, setStrategy] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [userName, setUserName] = useState("");
  const [location, setLocation] = useState("");
  const [goal, setGoal] = useState("");
  const [budget, setBudget] = useState("");
  const [visible, setVisible] = useState(false);
  const [emailSentModal, setEmailSentModal] = useState(false);
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");

  const { setTheme } = useNextTheme();
  const { isDark, type } = useTheme();

  const router = useRouter();

  function toggleLoading() {
    if (showLoading) {
      setShowLoading(false);
    } else {
      setShowLoading(true);
    }
  }

  useEffect(() => {
    if (auth.currentUser) {
      // console.log("user is not null., lets get their profile");
      getUserProfile(auth.currentUser.email);
    } else {
      router.push("/login");
    }
  }, []);

  async function getUserProfile(email) {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      setName(docSnap.get("FirstName"));
      setBusinessName(docSnap.get("BusinessName"));
      setBusinessDescription(docSnap.get("BusinessDescription"));
      setBusinessLocation(docSnap.get("BusinessLocation"));
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such document!");
    }
  }

  return (
    <>
      <main className={styles.main}>
        <NavBarComponent />
        <Container>
          <Grid.Container gap={2} justify="center" alignContent="center">
            {showLoading ? (
              <>
                <Button>
                  <Loading color="currentColor" size="sm" /> &nbsp;
                  Generating...
                </Button>
              </>
            ) : (
              <>
                <Grid xs={12} lg={6}>
                  <Input
                    clearable
                    bordered
                    color="primary"
                    size="lg"
                    width="100%"
                    value={goal}
                    label="What's your goal with this marketing campaign?"
                    onChange={(e) => setGoal(e.target.value)}
                  />
                </Grid>
                <Grid xs={4}>
                  <Input
                    clearable
                    bordered
                    color="primary"
                    size="lg"
                    value={budget}
                    label="What's your budget?"
                    onChange={(e) => setBudget(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} justify="center" alignContent="center">
                  <Button onClick={() => getData()}>Generate Strategy</Button>
                </Grid>
              </>
            )}
          </Grid.Container>
          <Grid.Container gap={2} justify="center">
            {showLoading ? (
              <>
                <LoadingLottie />
              </>
            ) : (
              <>
                {strategy != "" ? (
                  <>
                    <Grid lg={12} justify="center">
                      <Button onPress={() => send()}>Email this to me</Button>
                    </Grid>
                    <Card css={{ mw: "85%" }}>
                      <Card.Body>
                        <ReactMarkdown className="strategy">
                          {strategy}
                        </ReactMarkdown>
                        {/* <div dangerouslySetInnerHTML={{ __html: strategy }}></div> */}
                        {/* {strategy} */}
                      </Card.Body>
                    </Card>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Grid.Container>
          <Modal
            closeButton
            preventClose
            blur
            aria-labelledby="modal-title"
            open={visible}
          >
            <Modal.Header>
              <Text id="modal-title" size={18}>
                Setup
              </Text>
            </Modal.Header>
            <Modal.Body>
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                value={businessName}
                label="Business Name"
              />
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                value={userName}
                label="Your Name"
              />
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                value={location}
                label="Business Location"
              />
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                value={businessDescription}
                label="Short description of your business"
              />
              <Row justify="space-between">
                <Checkbox>
                  <Text size={14}>Remember me</Text>
                </Checkbox>
                <Text size={14}>Forgot password?</Text>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size={"md"} onPress={() => setVisible(false)}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            closeButton
            aria-labelledby="modal-title"
            open={emailSentModal}
          >
            <Modal.Header>
              <Text id="modal-title" size={18}>
                Check your inbox
              </Text>
            </Modal.Header>
            <Modal.Body>Email has been sent successfully</Modal.Body>
            <Modal.Footer>
              <Button
                auto
                flat
                color="error"
                onPress={() => setEmailSentModal(false)}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </main>
    </>
  );

  function send() {
    //console.log("Sending emeil");
    const el = document.querySelector(".strategy");
    var mdHTML;
    if (el) {
      mdHTML = el.innerHTML;
      //setHtml(mdHTML);
    }

    var templateParams = {
      to: auth.currentUser.email,
      strategy: mdHTML,
    };

    emailjs
      .send(
        "service_vqt292o",
        "template_c91mujp",
        templateParams,
        "user_yZltI0Gl0uCvRA8AHAryM"
      )
      .then(
        (result) => {
          // console.log(result.status);
          setEmailSentModal(true);
          setStrategy("");
          setBudget("");
          setGoal("");
        },
        (error) => {
          //console.log(error.text);
        }
      );
  }

  async function getData() {
    var contentArray = [];

    //console.log("Sending the request: ");
    setShowLoading(true);

    var promptString =
      "Speak like you are a big time digital marketing agency. " +
      "My business name is " +
      businessName +
      ". " +
      "My business is located in " +
      location +
      ". " +
      "A description of my business is " +
      businessDescription +
      ". " +
      "\n" +
      " Here are some instructions on how to format your response: " +
      " - Format the response as markdown language. " +
      " - If there are any checklists, each list item should have an * in front of it. " +
      // "     <Card><Card.Body><Text>checklist item</Text></Card.Body></Card> ." +
      " - Speak to me as if I'm brand new to marketing and make sure the strategy is easy to understand. " +
      "Here's an examplem format of the document: " +
      " ## title of the marketing strategy \n" +
      " ** Budget: ** budget \n" +
      " ** Goal: ** goal \n " +
      " spacing \n" +
      " the rest of the marketing strategy";

    var contentString =
      "Create a digital marketing strategy for my business with a list of action items for this to succeed. The goal of this campaign is " +
      goal +
      ". " +
      "I have a total budget for the year of " +
      budget +
      " dollars.";

    //console.log(promptString);

    setPrompt(promptString + "\n" + contentString);

    await openai
      .createChatCompletion({
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content: promptString,
          },
          {
            role: "user",
            content: contentString,
          },
        ],
      })
      .then(async (completion) => {
        setShowLoading(false);
        // //console.log("raw data: " + JSON.stringify(completion));
        // //console.log();

        var content = completion.data.choices[0].message.content;

        setStrategy(content);

        var user_email = auth.currentUser.email;

        try {
          const body = {
            user_email,
            promptString,
            contentArray: completion.data.choices[0].message.content,
          };
          await fetch(`/api/strategies`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          });
          //await Router.push("/drafts");
        } catch (error) {
          console.error(error);
        }

        // console.log("Got " + content.split("|").length + " posts");

        // for (var i = 0; i < content.split("|").length; i++) {
        //   contentArray.push(content.split("|")[i]);
        // }

        // console.log(completion.data);

        // setPosts(contentArray);
      });

    //setData(contentArray);
  }
}
